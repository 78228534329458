import * as React from "react";
import Header from "../components/Header";
import Seo from "../components/Seo";
import { MDX, MDXProps } from "../components/Mdx";
import { getImage } from "gatsby-plugin-image";
import Footer from "../components/Footer";
import Banner from "../components/Banner";

const Standard = ({ data }: MDXProps) => {
  const { mdx } = data;
  const { frontmatter, body } = mdx;
  const headerImage = getImage(frontmatter.image);

  return (
    <>
      <Seo title={frontmatter.title} description={frontmatter.description} />
      <Header />
      <div className="flex flex-col min-h-screen">
        <main className="grow flex flex-col text-left md:text-justify">
          <Banner
            title={frontmatter.title}
            headerImage={headerImage}
            intro={frontmatter.intro}
            actionLink={frontmatter.actionLink}
            actionText={frontmatter.actionText}
          />
          <section className="relative bg-white grow">
            <div
              className={`${
                frontmatter.containerSize === "6xl" ? "max-w-6xl" : "max-w-2xl"
              } mx-auto px-4 py-16`}
            >
              <MDX frontmatter={frontmatter}>{body}</MDX>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Standard;
