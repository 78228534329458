import React from "react";
import { graphql } from "gatsby";
import Standard from "../layouts/Standard";

export default function Template<TData>({ data }: { data: TData }) {
  return <Standard data={data} children="" />;
}

export const pageQuery = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        description
        title
        image {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              formats: [AUTO, WEBP]
              placeholder: BLURRED
              backgroundColor: "transparent"
            )
          }
        }
        intro
        actionLink
        actionText
        containerSize
      }
    }
  }
`;
