import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer, MDXRendererProps } from "gatsby-plugin-mdx";
import Link from "./Link";
import { IGatsbyImageData } from "gatsby-plugin-image";

export interface MDXProps extends MDXRendererProps {
  frontmatter?: {
    title: string;
    image: IGatsbyImageData;
    anchor: string;
    description: string;
    logos: string[];
    intro?: string;
    actionLink?: URL;
    actionText?: string;
    containerSize?: string;
  };
}

const MDX = ({ frontmatter, children }: MDXProps) => {
  interface CustomLinkProps {
    href: string;
    children: React.ReactChild;
  }

  const CustomLink = ({ href, children, ...rest }: CustomLinkProps) => {
    return (
      <Link to={href} className="link" {...rest}>
        {children}
      </Link>
    );
  };

  interface ListContent {
    props: { children: React.ReactChild };
  }

  const CustomUL = (props: { children: ListContent[] }) => {
    return (
      <ul className="unordered-list">
        {React.Children.map(props.children, (child: ListContent, index: number) => (
          <li key={index}> {child?.props?.children}</li>
        ))}
      </ul>
    );
  };

  const CustomOL = (props: { children: ListContent[] }) => {
    return (
      <ol className="ordered-list">
        {React.Children.map(props.children, (child: ListContent, index: number) => (
          <li key={index}> {child?.props?.children}</li>
        ))}
      </ol>
    );
  };

  const CustomH2 = (props: { children: React.ReactChild; className: string }) => {
    return <h2 className={`mt-8 mb-4 ${props.className}`}>{props.children}</h2>;
  };

  const CustomCode = (props: { children: React.ReactChild }) => {
    return (
      <code className="p-2 mb-4 leading-6	text-sm bg-mg-purple text-white block break-all">
        {props.children}
      </code>
    );
  };

  const CustomStrike = (props: { children: React.ReactChild }) => {
    return <s className="no-underline bg-[#fff3] px-1 py-0.5 -mt-0.5">{props.children}</s>;
  };

  const Youtube = (props: { src: string }) => {
    return (
      <iframe
        className="w-full aspect-video my-8"
        width="560"
        height="315"
        src={props.src}
        title="YouTube video player"
        frameBorder="0"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
        allowFullScreen
      />
    );
  };

  const components = {
    ul: CustomUL,
    ol: CustomOL,
    h2: CustomH2,
    code: CustomCode,
    a: CustomLink,
    YouTube: Youtube,
    s: CustomStrike,
  };

  return (
    <MDXProvider components={components}>
      <MDXRenderer frontmatter={frontmatter}>{children}</MDXRenderer>
    </MDXProvider>
  );
};

export { MDX };
